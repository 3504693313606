import React, { lazy, Suspense } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as ROUTES from "./constants/routes";
function App() {
  const Home = lazy(() => import("./screens/Home"));
  const Connections= lazy(() => import("./screens/Connections"));
  const Contact= lazy(() => import("./screens/Contact"));
  const Products= lazy(() => import("./screens/Products"));
  const Privacy= lazy(() => import("./screens/Privacy"));
  const Catalogue= lazy(() => import("./screens/Catalogue"));
  const loading = (
    <div class="loader">
   <div class="circle">
     <div class="dot"></div>
     <div class="outline"></div>
   </div>
   <div class="circle">
     <div class="dot"></div>
     <div class="outline"></div>
   </div>
   <div class="circle">
     <div class="dot"></div>
     <div class="outline"></div>
   </div>
   <div class="circle">
     <div class="dot"></div>
     <div class="outline"></div>
   </div>
 </div>
   );
  return (
    <Router>
    <Suspense fallback={loading}>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.CONNECTIONS} element={<Connections />} />
      <Route path={ROUTES.CONTACT} element={<Contact />} />
      <Route path={ROUTES.PRODUCTS} element={<Products />} />
      <Route path={ROUTES.PRIVACY} element={<Privacy />} />
      <Route path={ROUTES.CATALOGUE} element={<Catalogue />} />


        </Routes>
        </Suspense>
    </Router>
  );
}

export default App;
